// extracted by mini-css-extract-plugin
export var advantageItem = "style-module--advantage-item--6861f";
export var advantageItemBtn = "style-module--advantage-item-btn--cc728";
export var advantageItemDescription = "style-module--advantage-item-description--fd65d";
export var advantageItemHeader = "style-module--advantage-item-header--5c414";
export var advantageItemImg = "style-module--advantage-item-img--1be7a";
export var advantageItemText = "style-module--advantage-item-text--21612";
export var advantageItemTitle = "style-module--advantage-item-title--16f22";
export var advantageItems = "style-module--advantage-items--f4797";
export var background = "style-module--background--856be";
export var backgroundImg = "style-module--background-img--fa73a";
export var bannerDescription = "style-module--banner-description--475dc";
export var bannerDownload = "style-module--banner-download--d502f";
export var bannerDownloadAppStore = "style-module--banner-download-app-store--2c806";
export var bannerDownloadGoogleStore = "style-module--banner-download-google-store--a5853";
export var bannerDownloadLink = "style-module--banner-download-link--9f2d0";
export var bannerImg = "style-module--banner-img--9dda3";
export var bannerText = "style-module--banner-text--6ab2c";
export var bannerTitle = "style-module--banner-title--cfb26";
export var containerAdvantages = "style-module--container-advantages--52fe8";
export var containerBanner = "style-module--container-banner--20a14";
export var containerOpenWallet = "style-module--container-open-wallet--55a3f";
export var containerServices = "style-module--container-services--4e943";
export var containerSoonCard = "style-module--container-soon-card--8d6f6";
export var containerTop = "style-module--container-top--7daca";
export var containerWhyWallet = "style-module--container-why-wallet--d5504";
export var createWalletBtn = "style-module--create-wallet-btn--4a9e2";
export var description = "style-module--description--3c43b";
export var openWalletStepItem = "style-module--open-wallet-step-item--eb986";
export var openWalletStepItemDescription = "style-module--open-wallet-step-item-description--dc6fd";
export var openWalletStepItemNum = "style-module--open-wallet-step-item-num--2ce9d";
export var openWalletStepItemTitle = "style-module--open-wallet-step-item-title--5923a";
export var openWalletSteps = "style-module--open-wallet-steps--6801c";
export var openWalletTitle = "style-module--open-wallet-title--00817";
export var serviceItem = "style-module--service-item--b4d8b";
export var serviceItemContainerImg = "style-module--service-item-container-img--9f5e5";
export var serviceItemDescription = "style-module--service-item-description--bfc9d";
export var serviceItemImg = "style-module--service-item-img--b03b9";
export var serviceItemLink = "style-module--service-item-link--aff3f";
export var serviceItemTitle = "style-module--service-item-title--5692a";
export var serviceItems = "style-module--service-items--1aac0";
export var serviceTitle = "style-module--service-title--c49a7";
export var soonCardText = "style-module--soon-card-text--2b6dc";
export var text = "style-module--text--5f590";
export var title = "style-module--title--59322";
export var tryButton = "style-module--try-button--c0547";
export var whyWalletContainerImg = "style-module--why-wallet-container-img--861b9";
export var whyWalletImg = "style-module--why-wallet-img--db3a1";
export var whyWalletText = "style-module--why-wallet-text--4083f";