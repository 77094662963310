import React from "react";
import { Helmet } from "react-helmet";
import * as styles from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import {
  commissionMinimal,
  commissionPercent,
  LAPTOP_MOBILE_POINTER,
  walletLink,
  walletStoreLinkIos,
  walletStoreLinkMd,
} from "@tools/constants";
import { ButtonUI, NoGapUI, TypographyUI, InfoTooltip } from "@ui";
import { BackgroundPosterCt, ButtonOfScrollCt, Layout } from "@components";
import sCardImg from "@images/wallet/service-card.png";
import sKassaImg from "@images/wallet/service-kassa.png";
import sReceiptImg from "@images/wallet/service-receipt.png";
import sWalletImg from "@images/wallet/service-wallet.png";
import srcNumStep1Img from "@images/wallet/num-1.svg";
import srcNumStep2Img from "@images/wallet/num-2.svg";
import srcNumStep3Img from "@images/wallet/num-3.svg";
import { useSendEvent } from "@tools/hooks";

const WalletPage = () => {
  const { tryWallet, openWallet, clickDownloadApple, clickDownloadGoogle } = useSendEvent();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Кошелек для самозанятых РФ электронный | Открыть кошелек самозанятого
        </title>
        <meta
          name="description"
          content="В поиске кошелька для самозанятых РФ? Удобный сервис для самозанятых граждан. Автоматизация налогов, чеков, бухгалтерии. Моментальный вывод денег на карту."
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/wallet"
        />
      </Helmet>
      <Layout>
        <div className={styles.containerTop}>
          <BackgroundPosterCt />
          <div className={styles.text}>
            <TypographyUI.H1
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >Платежный сервис <NoGapUI>для самозанятых</NoGapUI> лиц</TypographyUI.H1>
            <TypographyUI.Body1Regular
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.description}
            >
              Принимайте платежи, пользуйтесь интернет-эквайрингом, оплачивайте
              налоги и получайте отчетность в универсальном электронном кошельке
              самозанятого
            </TypographyUI.Body1Regular>

            <a
              target="_blank"
              href={walletLink}
              onClick={tryWallet}
            >
              <ButtonUI
                className={styles.tryButton}
                onClick={() => {}}
                $w="240px"
              >
                Попробовать
              </ButtonUI>
            </a>
          </div>

          <div className={styles.background}>
            <StaticImage
              className={styles.backgroundImg}
              src="../../images/wallet/background.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={576}
              height={523}
              layout="fixed"
              objectFit="contain"
              alt="background-img"
            />
          </div>
        </div>

        <ButtonOfScrollCt id="whyWallet" $w="100%" $mt="120px" />

        <div id="whyWallet" className={styles.containerWhyWallet}>
          <div className={styles.whyWalletContainerImg}>
            <StaticImage
              className={styles.whyWalletImg}
              src="../../images/wallet/why-wallet.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={557}
              height={620}
              layout="fixed"
              objectFit="contain"
              alt="why-wallet-img"
            />
          </div>
          <div className={styles.whyWalletText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >
              Зачем мне Кошелек Самозанятые.рф
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.description}>
              Представьте, что вы — самозанятый парикмахер. Каждый день через вас проходят клиенты, у которых не всегда есть наличные. Можно запросить перевод на карту, а можно отправить ссылку или QR-код для моментальной оплаты.
            </TypographyUI.Body1Regular>
            <TypographyUI.Body1Regular className={styles.description}>
              Удобство ссылки в том, что клиенту не понадобится открывать приложение онлайн-банка, вводить ваши реквизиты, указывать сумму, ждать подтверждения платежа и просить у вас чек — кошелек сделает это за вас
            </TypographyUI.Body1Regular>
          </div>
        </div>

        <div className={styles.containerAdvantages}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.title}
          >
            Узнайте о преимуществах Кошелька
          </TypographyUI.H2>

          <div className={styles.advantageItems}>
            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-1.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-1"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.Body1Medium
                  className={styles.advantageItemHeader}
                >
                  Финансы
                </TypographyUI.Body1Medium>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Прием платежей и вывод денег
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Получайте деньги по ссылке, QR-коду или через систему
                  интернет-экваринга и моментально выводите средства любым
                  удобным способом
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-6.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-6"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Простой вывод средств
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Вы получаете деньги на специальный электронный кошелек. Их
                  можно вывести на карты любых банков и другие электронные
                  кошельки
                </TypographyUI.Body1Regular>
                <a target="_blank" href={walletLink}>
                  <ButtonUI.Secondary
                    className={styles.advantageItemBtn}
                    onClick={() => {}}
                    $w="240px"
                  >
                    Перейти в Кошелек
                  </ButtonUI.Secondary>
                </a>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-2.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-2"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.Body1Medium
                  className={styles.advantageItemHeader}
                >
                  Налоги
                </TypographyUI.Body1Medium>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Интеграция с ФНС
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Кошелек сам формирует чеки и отправляет отчетность в
                  приложение «Мой налог»
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-3.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-3"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.Body1Medium
                  className={styles.advantageItemHeader}
                >
                  Сервис
                </TypographyUI.Body1Medium>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Пользуйтесь бесплатно
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Вы платите только комиссию в размере {commissionPercent}% за вывод денежных средств
                  <InfoTooltip id={'commission-tooltip'}>
                    <TypographyUI.CaptionRegular $c={'var(--text-contrast)'}>Минимальный размер комиссии {commissionMinimal} ₽</TypographyUI.CaptionRegular>
                  </InfoTooltip>
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-4.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-4"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.Body1Medium
                  className={styles.advantageItemHeader}
                >
                  Работа
                </TypographyUI.Body1Medium>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Интеграция с Маркетом
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Подключитесь к бирже для самозанятых и настройте получение
                  денег прямо на баланс Кошелька
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/wallet/advantage-7.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-7"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.Body1Medium
                  className={styles.advantageItemHeader}
                >
                  Автоматизация
                </TypographyUI.Body1Medium>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Платежи в Телеграм
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Настройте автоматический прием платежей с помощью Телеграм-бота
                </TypographyUI.Body1Regular>
                <Link to="/telegram">
                  <ButtonUI.Secondary
                      className={styles.advantageItemBtn}
                      onClick={() => {}}
                      $w="240px"
                  >
                    Подробнее
                  </ButtonUI.Secondary>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerServices}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.serviceTitle}
          >
            Безграничные возможности Кошелька
          </TypographyUI.H2>
          <div className={styles.serviceItems}>
            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-wallet"
                  src={sWalletImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Учитывайте наличные платежи
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Получили от клиента наличные? Введите информацию в
                  Кошелек, а он сам отправит отчетность в ФНС
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-receipt"
                  src={sReceiptImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Выставляйте счета
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Юридическим лицам и ИП потребуется счет для работы с вами. Его можно создать через личный кабинет сервиса
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-kassa"
                  src={sKassaImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Касса
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Создайте многоразовую ссылку на оплату конкретной услуги или товара. Ее можно отправить клиенту любым удобным способом или разместить на сайте
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-card"
                  src={sCardImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Интернет-эквайринг
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Подключите полноценную платежную систему к вашему сайту и
                  получайте оплату прямо на карту
                </TypographyUI.Body1Regular>
                <Link to="/acquiring">
                  <TypographyUI.Body1Regular className={styles.serviceItemLink}>
                    Подробнее
                  </TypographyUI.Body1Regular>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerSoonCard}>
          <div className={styles.soonCardText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >
              Скоро — платежные карты
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.description}>
              С платежной картой Сам.Кард вы забудете о переводах и комиссиях —
              приложите пластиковую или электронную карту к терминалу и оплатите
              покупку доступным балансом Кошелька
            </TypographyUI.Body1Regular>
          </div>
          <div>
            <StaticImage
              src="../../images/wallet/soon-card.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              layout="constrained"
              objectFit="contain"
              alt="soon-card"
            />
          </div>
        </div>

        <div className={styles.containerBanner}>
          <div className={styles.bannerText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.bannerTitle}
            >
              Кошелек самозанятого: открыть и начать работать
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.bannerDescription}>
              Скачайте кошелек для самозанятых граждан и начните получать деньги онлайн. Ваши клиенты смогут оплатить товары и услуги любым удобным способом
            </TypographyUI.Body1Regular>
            <div className={styles.bannerDownload}>
              <a
                className={styles.bannerDownloadLink}
                target="_blank"
                href={walletStoreLinkIos}
                onClick={clickDownloadApple}
              >
                <StaticImage
                  className={styles.bannerDownloadAppStore}
                  src="../../images/shared/app-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
              <a
                className={styles.bannerDownloadLink}
                target="_blank"
                href={walletStoreLinkMd}
                onClick={clickDownloadGoogle}
              >
                <StaticImage
                  className={styles.bannerDownloadGoogleStore}
                  src="../../images/shared/google-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
            </div>
          </div>

          <div className={styles.bannerImg}>
            <StaticImage
              src="../../images/wallet/banner.png"
              loading="eager"
              placeholder="none"
              layout="constrained"
              objectFit="contain"
              alt="banner"
            />
          </div>
        </div>

        <div className={styles.containerOpenWallet}>
          <TypographyUI.H1
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.openWalletTitle}
            variant="h2"
          >
            Мгновенная регистрация
          </TypographyUI.H1>
          <div className={styles.openWalletSteps}>
            <div className={styles.openWalletStepItem}>
              <img
                className={styles.openWalletStepItemNum}
                src={srcNumStep1Img}
                alt="num-1"
              />
              <TypographyUI.H4Bold className={styles.openWalletStepItemTitle}>
                Введите телефон и задайте пароль
              </TypographyUI.H4Bold>
              <TypographyUI.Body1Regular
                className={styles.openWalletStepItemDescription}
              >
                Так вы создадите учетную запись физического лица и сможете
                принимать деньги от знакомых
              </TypographyUI.Body1Regular>
            </div>
            <div className={styles.openWalletStepItem}>
              <img
                className={styles.openWalletStepItemNum}
                src={srcNumStep2Img}
                alt="num-2"
              />
              <TypographyUI.H4Bold className={styles.openWalletStepItemTitle}>
                Укажите ИНН
              </TypographyUI.H4Bold>
              <TypographyUI.Body1Regular
                className={styles.openWalletStepItemDescription}
              >
                Кошелек направит запрос в приложение «Мой налог», чтобы
                проверить ваш официальный статус самозанятого
              </TypographyUI.Body1Regular>
            </div>
            <div className={styles.openWalletStepItem}>
              <img
                className={styles.openWalletStepItemNum}
                src={srcNumStep3Img}
                alt="num-3"
              />
              <TypographyUI.H4Bold className={styles.openWalletStepItemTitle}>
                Зайдите в «Мой налог»
              </TypographyUI.H4Bold>
              <TypographyUI.Body1Regular
                className={styles.openWalletStepItemDescription}
              >
                Дайте доступ Сам.Кошельку к «Моему налогу», чтобы получить
                доступ ко всем функциям платформы
              </TypographyUI.Body1Regular>
            </div>
          </div>
          <a target="_blank" onClick={openWallet} href={walletLink}>
            <ButtonUI
              className={styles.createWalletBtn}
              onClick={() => {}}
              $w="240px"
            >
              Открыть кошелек
            </ButtonUI>
          </a>
        </div>
      </Layout>
    </>
  );
};

export default WalletPage;
